import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import avatar_en from "../data/en/Avatars.json";
import { Button } from "react-bootstrap";
import avatar_vi from "../data/vi/Avatars.json";
import "./BaseHandle.css";
import { useTranslation } from "react-i18next";
import pkg from "../../package.json";

const webCommandAPI = pkg["web-command-api"];

function HandleCharacter() {
  const [selectedOption, setSelectedOption] = useState([]);
  const [level, setLevel] = useState(1);
  const [rank, setRank] = useState(1);
  const [isExecuting, setIsExecuting] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const avatarData = currentLanguage === "vi" ? avatar_vi : avatar_en;

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleLevelChange = (value) => {
    let newValue = parseInt(value);
    if (isNaN(newValue) || newValue < 1) {
      newValue = 1;
    } else if (newValue > 80) {
      newValue = 80;
    }
    setLevel(newValue);
  };

  const handleRankChange = (value) => {
    let newValue = parseInt(value);
    if (isNaN(newValue) || newValue < 1) {
      newValue = 1;
    } else if (newValue > 6) {
      newValue = 6;
    }
    setRank(newValue);
  };

  // Hàm xử lý nút Execute
  const handleExecuteGive = () => {
    if (isExecuting) return;
    setIsExecuting(true);

    try {
      const savedKey = sessionStorage.getItem("playerKey");
      if (!savedKey) return;

      if (!selectedOption) return;

      let commandValue = `/give ${selectedOption.value} lv${level} r${rank} s10`;
      if (commandValue.startsWith("/")) {
        commandValue = commandValue.substring(1);
      }

      const encodedCommand = encodeURIComponent(commandValue);
      const url = `${webCommandAPI}/excute/${savedKey}/${encodedCommand}`;

      fetch(url, { mode: "no-cors" })
        .then((res) => res.text())
        .catch(() => {});
    } catch (_) {}

    setTimeout(() => {
      setIsExecuting(false);
    }, 5000);
  };

  const handleExecuteAvatar = () => {
    if (isExecuting) return;
    setIsExecuting(true);

    try {
      const savedKey = sessionStorage.getItem("playerKey");
      if (!savedKey) return;

      if (!selectedOption) return;

      let commandValue = `/avatar ${selectedOption.value} lv${level} r${rank} s10`;
      if (commandValue.startsWith("/")) {
        commandValue = commandValue.substring(1);
      }

      const encodedCommand = encodeURIComponent(commandValue);
      const url = `${webCommandAPI}/excute/${savedKey}/${encodedCommand}`;

      fetch(url, { mode: "no-cors" })
        .then((res) => res.text())
        .catch(() => {});
    } catch (_) {}

    setTimeout(() => {
      setIsExecuting(false);
    }, 5000);
  };

  const inputRef3 = useRef(null);
  const handleInputClick3 = () => {
    if (inputRef3.current) {
      inputRef3.current.select();
      document.execCommand("copy");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Hide notification after 2 seconds
    }
  };

  const inputRef4 = useRef(null);
  const handleInputClick4 = () => {
    if (inputRef4.current) {
      inputRef4.current.select();
      document.execCommand("copy");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Hide notification after 2 seconds
    }
  };

  const handleFocusAndClick = (e) => {
    e.target.select();
  };

  return (
    <>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <h2 className="title">{t("avatars")}</h2>
          </div>
          <div className="col-sm-8"></div>
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-10">
            <div
              className="outdated-message"
              dangerouslySetInnerHTML={{ __html: t("beta-warning-message") }}
            />
          </div>
          <div className="col-sm-1"></div>
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("character-name")}</h5>
          </div>
          <div className="col-sm-6">
            <Select
              className="select"
              value={selectedOption}
              onChange={handleSelectChange}
              options={avatarData.map((avatar) => ({
                value: avatar.id,
                label: avatar.name,
              }))}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("level")}</h5>
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              value={level}
              onChange={(e) => handleLevelChange(e.target.value)}
              min={1}
              max={80}
              className="LevelInput"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("eidolon")}</h5>
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              value={rank}
              onChange={(e) => handleRankChange(e.target.value)}
              min={1}
              max={6}
              className="LevelInput"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h2 className="final-command">{t("command")}</h2>
          </div>
          <div className="col-sm-9" />
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-6">
            <div
              className="outdated-message"
              dangerouslySetInnerHTML={{
                __html: t("case_player_doesnt_have_avatar"),
              }}
            />
            {selectedOption && (
              <input
                type="text"
                value={`/give ${selectedOption.value} lv${level} r${rank} s10`}
                readOnly
                ref={inputRef3}
                className="command"
                onClick={handleInputClick3}
              />
            )}
          </div>
          {selectedOption && (
            <div className="col-sm-2">
              <br />
              <Button
                className="button-excute-command"
                onClick={handleExecuteGive}
                disabled={isExecuting}
              >
                {isExecuting ? "Wait..." : "Execute"}
              </Button>
            </div>
          )}
          <div className="col-sm-5" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-6">
            <div
              className="outdated-message"
              dangerouslySetInnerHTML={{ __html: t("case_player_have_avatar") }}
            />
            {selectedOption && (
              <input
                type="text"
                value={`/avatar ${selectedOption.value} lv${level} r${rank} s10`}
                readOnly
                ref={inputRef4}
                className="command"
                onClick={handleInputClick4}
              />
            )}
          </div>
          {selectedOption && (
            <div className="col-sm-2">
              <br />
              <Button
                className="button-excute-command"
                onClick={handleExecuteAvatar}
                disabled={isExecuting}
              >
                {isExecuting ? "Wait..." : "Execute"}
              </Button>
            </div>
          )}
          <div className="col-sm-5" />
        </div>
      </div>
      {showNotification && (
        <div className="notification">{t("copySuccess")}</div>
      )}
      <div style={{ marginTop: "100px" }} />
    </>
  );
}

export default HandleCharacter;
