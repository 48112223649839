import React, { useState, useEffect } from "react";
import { Col, Row, Modal, Button, Form } from "react-bootstrap";
import Sidebar from "./Sidebar";
import Cmdbeginner from "./component/Cmdbeginner";
import "./App.css";

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 900);
  // eslint-disable-next-line
  const [cmdbeginnerKey, setCmdbeginnerKey] = useState(Date.now());
  // Ban đầu, giao diện chính chưa được render (null)
  const [currentComponent, setCurrentComponent] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [playerKey, setPlayerKey] = useState("");

  // Khi App khởi tạo, xóa remote key (nếu có) để đảm bảo luôn rỗng khi modal mở
  useEffect(() => {
    sessionStorage.removeItem("playerKey");
    setPlayerKey("");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Khi modal đóng (nghĩa là người dùng đã nhập key), ta load lại giao diện chính
  useEffect(() => {
    if (!showModal) {
      const newKey = Date.now();
      setCmdbeginnerKey(newKey);
      setCurrentComponent(<Cmdbeginner key={newKey} />);
    }
  }, [showModal]);

  const handleInputChange = (event) => {
    setPlayerKey(event.target.value);
  };

  const handleSave = () => {
    sessionStorage.setItem("playerKey", playerKey);
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  const changeComponent = (component) => {
    setCurrentComponent(component);
  };

  return (
    <>
      {/* Modal nhập remote key */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="xl"
        className="custom-modal"
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title className="fs-2">
            <span className="modal-title-text">
              HotaruSR - Remote Server Key !
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-start custom-modal-body">
          <p className="notice-text">*Notice</p>
          <p className="modal-text">
            This feature is only supported in Version <b>3.1.5 or upper</b>.
            It allows players to execute commands directly from the website to
            their in-game account.
          </p>
          <p className="modal-text">
            Please use the command <b>/remote</b> in-game to get your player key.
            This key is only saved in your game session and will be removed after
            you log out or turn off your game!
          </p>
          <img
            src="https://raw.githubusercontent.com/arimetson/perfect-relics-lcweb/refs/heads/main/remoteCommandImage.png"
            alt="Welcome"
            className="img-fluid rounded w-100"
          />
          <p className="enter-text">Enter your player key below!</p>
          <Form.Control
            type="text"
            placeholder="Enter your player key..."
            value={playerKey}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="player-key-input"
          />
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
        <Button
            variant="primary"
            size="lg"
            className="custom-button-cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="lg"
            className="custom-button"
            onClick={handleSave}
          >
            Enter
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Chỉ render giao diện chính khi modal đã đóng */}
      {!showModal && (
        <Row className="overview-container">
          <Col md={isDesktop ? 3 : 0}>
            <Sidebar changeComponent={changeComponent} />
          </Col>
          <Col md={isDesktop ? 9 : 12}>{currentComponent}</Col>
        </Row>
      )}
    </>
  );
}

export default App;
