import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./BaseComponent.css";
import Cmdbeginner from "../component/Cmdbeginner";
import lcversion from "../data/version.txt";
import HandleCmdbeginner from "../Handle/HandleCmdbeginner";
import pkg from "../../package.json";

const webCommandAPI = pkg["web-command-api"];

// Hàm che đi nửa kí tự của key
function maskPlayerKey(key) {
  if (!key) return "";
  const halfLength = Math.floor(key.length / 3);
  // Hiển thị nửa đầu và che nửa sau bằng dấu *
  return key.substring(0, halfLength) + "*".repeat(key.length - halfLength);
}

function FreeCommand() {
  const { t } = useTranslation();
  const [version, setVersion] = useState("");
  // eslint-disable-next-line
  const [command, setCommand] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [isExecuting, setIsExecuting] = useState(false);
  // eslint-disable-next-line
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 900);
  // eslint-disable-next-line
  const [cmdbeginnerKey, setCmdbeginnerKey] = useState(Date.now());
  // eslint-disable-next-line
  const [currentComponent, setCurrentComponent] = useState(null);

  const playerKey = sessionStorage.getItem("playerKey");

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Khi modal đóng (nghĩa là người dùng đã nhập key), ta load lại giao diện chính
  useEffect(() => {
    if (!showModal) {
      const newKey = Date.now();
      setCmdbeginnerKey(newKey);
      setCurrentComponent(<Cmdbeginner key={newKey} />);
    }
  }, [showModal]);

  const handleInputChange = (event) => {
    setCommand(event.target.value);
  };

  const handleSave = () => {
    if (isExecuting) return;
    setIsExecuting(true);

    try {
      let commandValue = command;
      if (commandValue.startsWith("/")) {
        commandValue = commandValue.substring(1);
      }

      if (!playerKey && !commandValue.startsWith("unstuck")) return;

      let unstuckCommandKey = "unstuckcommandkey"

      const encodedCommand = encodeURIComponent(commandValue);
      const keyForUrl = commandValue.startsWith("unstuck")
        ? unstuckCommandKey
        : playerKey;
      const url = `${webCommandAPI}/excute/${keyForUrl}/${encodedCommand}`;

      fetch(url, { mode: "no-cors" })
        .then((res) => res.text())
        .catch(() => {});
    } catch (_) {}

    setTimeout(() => {
      setIsExecuting(false);
    }, 5000);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  useEffect(() => {
    // Đọc nội dung từ tệp version.txt khi component được tạo ra
    fetch(lcversion)
      .then((response) => response.text())
      .then((data) => setVersion(data))
      .catch((error) => console.error("Error fetching version:", error));
  }, []);

  return (
    <div>
      <p className="main-version">
        <span style={{ color: "gold" }}>
          Remote Key: {playerKey ? maskPlayerKey(playerKey) : "NULL"}
        </span>{" "}
        | {t("versionText")}: {version}
      </p>
      <HandleCmdbeginner />
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="xl"
        className="custom-modal"
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title className="fs-2">
            <span className="modal-title-text">
              HotaruSR - Remote Server Command !
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-start custom-modal-body">
          <p className="notice-text">*Notice</p>
          <p className="modal-text">
            This feature is only supported in Version <b>3.1.5 or upper</b>. It
            allows players to execute commands directly from the website to
            their in-game account.
          </p>
          <p className="modal-text">
            <span className="notice-text">{`>> `}</span>
            Please use the command <b style={{ color: "cyan" }}>/unstuck</b> and
            add your UID to unstuck yourself if you think your game are stucking
            in some mode, and you cannot enter the game normally.
          </p>
          <p className="modal-text">
            <span className="notice-text">{`>> `}</span>
            This function has the ability to ask you to enter the UID included
            in the command for some special commands (For example{" "}
            <b style={{ color: "cyan" }}>/unstuck @10014</b>)
          </p>
          <p className="modal-text">
            <span className="notice-text">{`>> `}</span>
            This function has same function as your chatbot ingame. Permission
            of this will be defined same as the Player having same Remote Key as
            you just set. Reload your website if you want to change your remote
            key.
          </p>
          <img
            src="https://raw.githubusercontent.com/arimetson/perfect-relics-lcweb/refs/heads/main/honkai-star-rail-castorice2.jpg"
            alt="Welcome"
            className="img-fluid rounded w-100"
          />
          <p className="enter-text">Enter your command below!</p>
          <Form.Control
            type="text"
            placeholder="Ex: /unstuck @10014"
            value={command}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="player-key-input"
          />
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
          <Button
            variant="primary"
            size="lg"
            className="custom-button-cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="lg"
            className="custom-button"
            onClick={handleSave}
            disabled={isExecuting}
          >
            {isExecuting ? "Wait..." : "Execute"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FreeCommand;
