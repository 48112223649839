import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./BaseComponent.css";
import lcversion from "../data/version.txt";
import HandleCharacter from "../Handle/HandleCharacter";

// Hàm che đi nửa kí tự của key
function maskPlayerKey(key) {
  if (!key) return "";
  const halfLength = Math.floor(key.length / 3);
  // Hiển thị nửa đầu và che nửa sau bằng dấu *
  return key.substring(0, halfLength) + "*".repeat(key.length - halfLength);
}

function Character() {
  const { t } = useTranslation();
  const [version, setVersion] = useState("");
  const [playerKey, setPlayerKey] = useState("");

  useEffect(() => {
    // Đọc nội dung từ file version.txt khi component được tạo ra
    fetch(lcversion)
      .then((response) => response.text())
      .then((data) => setVersion(data))
      .catch((error) => console.error("Error fetching version:", error));
  }, []);

  useEffect(() => {
    // Lấy player key đã lưu từ sessionStorage
    const savedKey = sessionStorage.getItem("playerKey");
    if (savedKey) {
      setPlayerKey(savedKey);
    }
  }, []);

  return (
    <div>
      <p className="main-version">
        <span style={{color: 'gold'}}>Remote Key: {playerKey ? maskPlayerKey(playerKey) : "NULL"}</span> | {t("versionText")}: {version}
      </p>
      <HandleCharacter />
    </div>
  );
}

export default Character;
