import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import items_en from "../data/en/Items.json";
import items_vi from "../data/vi/Items.json";
import "./BaseHandle.css";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import pkg from "../../package.json";

const webCommandAPI = pkg["web-command-api"];

function HandleItems() {
  const [selectedOption, setSelectedOption] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const itemsData = currentLanguage === "vi" ? items_vi : items_en;

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleQuantityChange = (value) => {
    let newValue = parseInt(value);
    if (isNaN(newValue) || newValue < 1) {
      newValue = 1;
    }
    setQuantity(newValue);
  };

  const inputRef3 = useRef(null);
  const handleInputClick3 = () => {
    if (inputRef3.current) {
      inputRef3.current.select();
      document.execCommand("copy");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Hide notification after 2 seconds
    }
  };

  // Hàm xử lý nút Execute
  const handleExecute = () => {
    if (isExecuting) return;
    setIsExecuting(true);

    try {
      const savedKey = sessionStorage.getItem("playerKey");
      if (!savedKey) return;

      if (!selectedOption) return;

      let commandValue = `/give ${selectedOption.value} x${quantity}`;
      if (commandValue.startsWith("/")) {
        commandValue = commandValue.substring(1);
      }

      const encodedCommand = encodeURIComponent(commandValue);
      const url = `${webCommandAPI}/excute/${savedKey}/${encodedCommand}`;

      fetch(url, { mode: "no-cors" })
        .then((res) => res.text())
        .catch(() => {});
    } catch (_) {}

    setTimeout(() => {
      setIsExecuting(false);
    }, 5000);
  };

  const handleFocusAndClick = (e) => {
    e.target.select();
  };

  return (
    <>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <h2 className="title">{t("items")}</h2>
          </div>
          <div className="col-sm-8" />
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-10">
            <div
              className="outdated-message"
              dangerouslySetInnerHTML={{ __html: t("beta-warning-message") }}
            />
          </div>
          <div className="col-sm-1"></div>
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("items-name")}</h5>
          </div>
          <div className="col-sm-6">
            <Select
              className="select"
              value={selectedOption}
              onChange={handleSelectChange}
              options={itemsData.map((items) => ({
                value: items.id,
                label: items.name,
              }))}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("quantity")}</h5>
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              value={quantity}
              onChange={(e) => handleQuantityChange(e.target.value)}
              className="LevelInput"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h2 className="final-command">{t("command")}</h2>
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-6">
            {selectedOption && (
              <input
                type="text"
                value={`/give ${selectedOption.value} x${quantity}`}
                readOnly
                ref={inputRef3}
                className="command"
                onClick={handleInputClick3}
                onFocus={handleFocusAndClick}
              />
            )}
          </div>
          {selectedOption && (
            <div className="col-sm-2">
              <Button
                className="button-excute-command-lightcone"
                onClick={handleExecute}
                disabled={isExecuting}
              >
                {isExecuting ? "Executing..." : "Execute"}
              </Button>
            </div>
          )}
          <div className="col-sm-5" />
        </div>
      </div>
      {showNotification && (
        <div className="notification">{t("copySuccess")}</div>
      )}
      <div style={{ marginTop: "100px" }} />
    </>
  );
}

export default HandleItems;
